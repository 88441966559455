const invokeError = (inputElement, errorText) => {
	const inputElementContainer = document.querySelector(
		'#' + inputElement.id + '-container',
	);

	const statusIndicatorWrapper =
		inputElementContainer.getElementsByClassName('status-indicator');
	statusIndicatorWrapper[0].style.display = 'none';
	const errorWrapper =
		inputElementContainer.getElementsByClassName('error-box');
	errorWrapper[0].style.display = 'flex';
	errorWrapper[0].style.color = 'red';
	errorWrapper[0].style['border-color'] = 'red';

	errorWrapper[0].querySelector('svg').style.color = 'red';
	errorWrapper[0].querySelector('span').innerText = errorText;
};

const clearError = (inputElement) => {
    const inputElementContainer = document.querySelector(
		'#' + inputElement.id + '-container',
	);
	const errorWrapper =
		inputElementContainer.getElementsByClassName('error-box');
	errorWrapper[0].style.display = 'none';
};

const enableButton = (buttons) => {
    buttons.forEach( button => {
        button.disabled = false;
        button.style.cursor = null;
        button.style.backgroundColor = null;
    })
};

const disableButton = (buttons) => {
    const disabledColor = "#cccccc";
    const disabledCursor = "not-allowed";
    
    buttons.forEach( button => {
        button.disabled = true;
        button.style.backgroundColor = disabledColor;
        button.style.cursor = disabledCursor;
    })
    
};

 const verifyIbanClientSide = (iban) => {
    // Remove any spaces and convert to uppercase
    iban = iban.replace(/\s/g, "").toUpperCase();

    // Check if the IBAN has a valid length
    if (iban.length < 4 || iban.length > 34) {
      return false;
    }

    // Rearrange the IBAN
    const rearrangedIBAN = iban.slice(4) + iban.slice(0, 4);

    // Convert letters to numbers (A=10, B=11, ..., Z=35)
    const numericIBAN = rearrangedIBAN.replace(/[A-Z]/g, (match) => {
      return match.charCodeAt(0) - 55;
    });

    // Calculate the remainder of the division by 97
    const remainder = numericIBAN.split("").reduce((previous, current) => {
      return (Number(previous + current) % 97).toString();
    }, "");

    return remainder === "1" ? true : false;
}

let submitButtons;

window.addEventListener('heyflow-init', () => {
    const buttons = document.body.querySelectorAll(".submit");
    submitButtons = buttons;
    disableButton(submitButtons);
})

window.addEventListener("heyflow-change", event => {
    const errorText = "Bitte gebe Sie eine gültige IBAN ein";
    const ibanInputs = document.querySelectorAll('[data-variable="iban"]');
    
    ibanInputs.forEach( iban => {
        iban.heyflowOnValidate = async (value) => {
            const validateIBAN = verifyIbanClientSide(value);
        
            if (validateIBAN) {
                enableButton(submitButtons);
                clearError(iban);
                return validateIBAN;
            } else {
                disableButton(submitButtons);
                invokeError(iban, errorText);
                return validateIBAN;
            }
        }
    })
})